import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Header from '../../Website/Header';
import Footer from '../Footer';

import { useNavigate, useParams } from 'react-router-dom';
import { getBroadcastDetail, broadcastHistoryCOunt } from '../../../api/broadcastApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from '../../common/Loader';

function BroadcastDetail() {
    const navigate = useNavigate();
    const [broadcastContacts, setBroadcastContacts] = useState([]);
    const [broadcastHistoryCount, setBroadcastHistoryCount] = useState({})
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [status, setStatus] = useState('');
    const { logoutUser } = useAuthContext();
    const { broadcastId } = useParams();

    const handleBroadcastDetail = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: limit,
                skip: skip,
                status: status,
                id: broadcastId
            }
            const responceData = await getBroadcastDetail(postData);
            if (responceData?.data?.data) {
                setBroadcastContacts(responceData.data.data);
            } else {
                setBroadcastContacts([]);
            }

        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Broadcast list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleBroadcastHistoryCount = async () => {
        try {
            let postData = {
                id: broadcastId
            }
            const responceData = await broadcastHistoryCOunt(postData);
            if (responceData?.data) {
                setBroadcastHistoryCount(responceData.data)
                // setBroadcastContacts(responceData.data.data);
            } else {
                setBroadcastContacts([]);
            }

        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Broadcast list failed';
                toast.error(errorMessage);
            }

        }
    }
    useEffect(() => {
        handleBroadcastDetail();
        handleBroadcastHistoryCount();
    }, [status])

    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">


                <h5 className="title">Broadcast Detail</h5>
                <div className="col mb-2" style={{ float: 'right' }}>
                    <button onClick={(e) => navigate('/broadcast')} className="btn btn-theme btn-md" >Back</button>
                </div>
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('')
                        }}
                        className="card border-0 mb-4 brodcast-count-color-total cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white rounded-circle">
                                            <Icon.BroadcastPin />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Total</p>
                                        <h5>{broadcastHistoryCount.countTotal}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('isSent')
                        }}
                        className="card border-0 mb-4 brodcast-count-color-sent cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white  rounded-circle">
                                            <Icon.CheckCircle />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Sent</p>
                                        <h5>{broadcastHistoryCount.countSent} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('isDelivered')
                        }}

                        className="card border-0 mb-4 brodcast-count-color-delivered cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white rounded-circle">
                                            <Icon.Truck />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Delivered</p>
                                        <h5 className="fw-medium">{broadcastHistoryCount.countDelivered}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('isRead')
                        }}
                        className="card border-0 mb-4 brodcast-count-color-read cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white rounded-circle">
                                            <Icon.Book />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Read</p>
                                        <h5 className="fw-medium">{broadcastHistoryCount.countRead}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('isFailed')
                        }}
                        className="card border-0 mb-4 brodcast-count-color-failed cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white rounded-circle">
                                            <Icon.XCircle />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Failed</p>
                                        <h5 className="fw-medium">{broadcastHistoryCount.countFailed}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xxl-2">
                        <div 
                        onClick={()=>{
                            setStatus('isResponse')
                        }}
                        className="card border-0 mb-4 brodcast-count-color-response cursor-pointer">
                            <div className="card-body bg-none">
                                <div className="row gx-2 align-items-center">
                                    <div className="col-auto">
                                        <div className="avatar avatar-40 h5 bg-light-white rounded-circle">
                                            <Icon.Reply />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted small mb-1">Response</p>
                                        <h5 className="fw-medium">{broadcastHistoryCount.countResponse}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border-0 mb-4">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12 col-md-auto">
                                <div className="input-group ">
                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search..." />
                                </div>
                            </div>
                            <div className="col-12 col-md-auto">
                                <div className="input-group ">
                                    {status===''?'Total Broadcasted Contacts... ':
                                    status==='isSent'?'Sent to Contacts... ':
                                    status==='isDelivered'?'Delivered to Contacts... ':
                                    status==='isRead'?'Read By Contacts... ':
                                    status==='isFailed'?'Failed to Contacts... ':
                                    status==='isResponse'?'Response by Contacts... ':
                                    ''}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <table className="table footable" data-show-toggle="true">
                            <thead>
                                <tr className="text-muted">
                                    <th>Sr No.</th>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(broadcastContacts.length > 0) ? (
                                    broadcastContacts.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.contactData[0].name}</td>
                                            <td>{item.contactData[0].mobileNoWithCode}</td>

                                        </tr>

                                    ))
                                ) : (
                                    <tr key="0" >
                                        <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                    </tr>
                                )}



                            </tbody>
                        </table>
                    </div>

                </div>


            </div>


        </main>
        <Footer />

    </>)
}

export default BroadcastDetail;