// src/components/common/Loader.js
import React from 'react';
import loaderImg from '../../assets/img/loader.gif'
const Loader = () => (
  <div>
    <img
      style={{
        width: '100%',
        height: '100%'
      }}
      src={loaderImg}
    />
  </div>
);

export default Loader;
