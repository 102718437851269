import React, { useEffect } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function PageNotFound() {
    const navigate = useNavigate();
    return (<>
        <Header />
        
        <main className="main mainheight" >
            <div className="row h-100" >
                <div className="col-12 col-md-12 h-100 overflow-y-auto">
                    <div className="row h-100">
                        <div className="col-12 align-self-center py-4 text-center">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-4 text-black">
                                    <div className="position-relative z-index-1">
                                        <img src="assets/img/404-1.png" alt="" className="mw-300" />
                                    </div>
                                    <h1 className="error404 mb-4 mtop-100 position-relative text-red">404</h1>

                                    <div className="z-index-1 position-relative">
                                        <h1 className="display-5 top-50">Page not found</h1>
                                        <p className="h4 fw-light mb-4">Its looks like you are landed on wrong planet.</p>
                                        <p className="text-muted mb-4">Kindly check URL, Report us a brocken link or Try after sometime. Go back to "Home" all the other things are working fine and you can continue with your tasks.</p>


                                        <a href="#" onClick={(e)=> navigate('/')} className="btn btn-lg btn-theme z-index-5 mb-3"><i className="bi bi-arrow-left"></i> Go back to Home </a>
                                        <p className="small text-muted">Don't take it seriously,<br />we just mean with this project "Home" page.</p>

                                        <p className="mt-3 mt-lg-4">Stay tuned with us</p>
                                        <ul className="nav justify-content-center">
                                            <li className="nav-item">
                                                <a className="nav-link text-white" href="https://www.facebook.com/maxartkiller/" target="_blank">
                                                    <i className="bi bi-facebook h5"></i>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-white" href="https://twitter.com/maxartkiller" target="_blank">
                                                    <i className="bi bi-twitter h5"></i>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-white" href="https://linkedin.com/company/maxartkiller" target="_blank">
                                                    <i className="bi bi-linkedin h5"></i>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-white" href="https://www.instagram.com/maxartkiller/" target="_blank">
                                                    <i className="bi bi-instagram h5"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main>

    </>)
}

export default PageNotFound;