import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { broadcastInitialValues, broadcastSchema, } from '../../../utils/validationSchema';
import { sendBroadcastByCloud } from '../../../api/broadcastApi';
import { getGroupList } from '../../../api/groupApi';
import { getUserDetails } from '../../../api/userApi'
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import { getBroadcastTemplateList } from '../../../api/templateApi';
import Pagination from '../../common/Pagination';
const socket = io(process.env.REACT_APP_API_BASE_URL);

function SendBroadcast() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const navigate = useNavigate();
    const { logoutUser } = useAuthContext();
    const [groupList, setGroupList] = useState([]);
    // const [filePreview, setFilePreview] = useState(null);
    const [templateList, setTemplateList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const [groupItemsPerPage] = useState(5);
    const [groupCurrentPage, setGroupCurrentPage] = useState(1);
    const [groupTotalItems, setGroupTotalItems] = useState(0)
    const [groupFinalSearchText, setGroupFinalSearchText] = useState('')
    const [groupSearch, setGroupSearch] = useState('')
    const [groupTimerId, setGroupTimerId] = useState(null);

    const [templateItemsPerPage] = useState(5);
    const [templateCurrentPage, setTemplateCurrentPage] = useState(1);
    const [templateTotalItems, setTemplateTotalItems] = useState(0)
    const [templateSearch, setTemplateSearch] = useState('')
    const [templateFinalSearchText, setTemplateFinalSearchText] = useState('')
    const [templateTimerId, setTemplateTimerId] = useState(null);

    const [headerPreview, setHeaderPreview] = useState('')
    const [bodyPreview, setBodyPreview] = useState('');
    const [footerPreview, setFooterPreview] = useState('')
    const [buttonTypePreview, setButtonTypePreview] = useState('')
    const [quickReplyPreview, setQuickReplyPreview] = useState([''])
    const [fileImgPreview, setImgFilePreview] = useState(null);
    const [fileVdoPreview, setVdoFilePreview] = useState(null);
    const [documentPreview, setDocumentPreview] = useState(null);




    const handleGroupList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: groupItemsPerPage,
                skip: (groupCurrentPage - 1) * groupItemsPerPage,
                name: groupFinalSearchText
            }
            const responceData = await getGroupList(postData);
            if (responceData?.result?.data) {
                setGroupTotalItems(responceData.result.count)
                setGroupList(responceData.result.data);
            } else {
                setGroupTotalItems(0)
                setGroupList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Group list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleTemplateList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: templateItemsPerPage,
                skip: (templateCurrentPage - 1) * templateItemsPerPage,
                search: templateFinalSearchText,
                status: 'APPROVED'
            }
            const responceData = await getBroadcastTemplateList(postData);
            if (responceData?.data) {
                setTemplateTotalItems(responceData.count)
                setTemplateList(responceData.data);
            } else {
                setTemplateTotalItems(0)
                setTemplateList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (formData) => {
        console.log('formData', formData);

        setLoading(true);
        try {
            let postData = {
                name: formData.title,
                description: formData.description,
                groupId: selectedGroup._id,
                templateId: selectedTemplate._id,
            }
            console.log('postData', postData);
            let responceData = await sendBroadcastByCloud(postData);
            toast.success('Broadcast send successful!');
            navigate('/broadcast')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create broadcast failed';
            if (errorMessage === "Session not found.") {

                toast.error("Your whatsapp session timeout. Please again scan QR..");
            } else {
                toast.error(errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };


    // Event handler for input change
    const handleGroupSearchChange = (event) => {
        const text = event.target.value;
        setGroupSearch(text);

        // Debouncing logic
        if (groupTimerId) {
            clearTimeout(groupTimerId);
        }
        const newTimerId = setTimeout(() => {
            setGroupFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setGroupTimerId(newTimerId);
    };

    // Event handler for input change
    const handleTemplateSearchChange = (event) => {
        const text = event.target.value;
        setTemplateSearch(text);

        // Debouncing logic
        if (templateTimerId) {
            clearTimeout(templateTimerId);
        }
        const newTimerId = setTimeout(() => {
            setTemplateFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTemplateTimerId(newTimerId);
    };

    useEffect(() => {
        handleGroupList();
    }, [groupCurrentPage, groupFinalSearchText])

    useEffect(() => {
        handleTemplateList();
    }, [templateCurrentPage, templateFinalSearchText])

    useEffect(() => {
        if(!selectedTemplate) return
        console.log('selectedTemplate', selectedTemplate);
        setHeaderPreview(null)
        setImgFilePreview(null)
        setVdoFilePreview(null)
        setDocumentPreview(null)
        let prevTemplateInfo = selectedTemplate;
        if (prevTemplateInfo.headerType === "TEXT") {
            setHeaderPreview(prevTemplateInfo.headerText);

        } else if (prevTemplateInfo.headerType === "IMAGE") {
            setImgFilePreview(prevTemplateInfo.headerFile)

        } else if (prevTemplateInfo.headerType === "VIDEO") {
            setVdoFilePreview(prevTemplateInfo.headerFile)

        } else if (prevTemplateInfo.headerType === "DOCUMENT") {
            setDocumentPreview(prevTemplateInfo.headerFile)
        }
        setBodyPreview(prevTemplateInfo.body)
        setFooterPreview(prevTemplateInfo.footer)
    }, [selectedTemplate])
    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">
                <div className="row top-row">
                    <div className="col-12 col-md-3 col-lg-3">
                        <div className="card bg-white border-0 text-center mb-4">
                            <div className="card-body p-4 px-lg-5">
                                <div className='qr-div-h'>
                                    <Icon.CollectionFill className='bi display-4 text-theme mb-2 d-block' />
                                </div>
                                <h4>Group</h4>
                                <p className="text-secondary">Create and select a group for broadcast.</p>
                            </div>
                        </div>
                        <div className="card bg-white border-0 text-center mb-4 ">
                            <div className="card-body  ">
                                <div className="col-12">
                                    <div className="input-group ">
                                        <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                        <input type="text" className="form-control" placeholder="Search..." value={groupSearch} onChange={handleGroupSearchChange} />
                                    </div>
                                </div>
                                <div className="card border-0 mb-4">
                                    <div className="card-body">
                                        <div className="row">


                                            <div className="col-12  border-left-dashed">

                                                <ul className="list-group list-group-flush bg-none">
                                                    <li className="list-group-item text-secondary">
                                                        {(groupList.length > 0) ? (
                                                            groupList.map((item, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="col-auto">
                                                                        <input type="radio" name='group' id={`group_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setSelectedGroup(item)
                                                                        }} />&nbsp;
                                                                        <label className='cursor-pointer' htmlFor={`group_id_${item._id}`}> {item.name}</label>
                                                                    </div>
                                                                    <div className="col-auto ps-0">[Contacts - {item.totalContact}]</div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="row">
                                                                <p>    No data available.. </p>
                                                            </div>

                                                        )}
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {groupTotalItems > 0 &&
                                    <Pagination
                                        currentPage={groupCurrentPage}
                                        totalItems={groupTotalItems}
                                        itemsPerPage={groupItemsPerPage}
                                        setCurrentPage={setGroupCurrentPage}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={selectedTemplate ? 'col-12 col-md-3 col-lg-3' : 'col-12 col-md-6 col-lg-6'}>
                        <div className="card bg-white border-0 text-center mb-4">
                            <div className="card-body p-4 px-lg-5">
                                <div className='qr-div-h'>
                                    <Icon.FileEarmarkRichtext className='bi display-4 text-theme mb-2 d-block' />
                                </div>
                                <h4>Templete</h4>
                                <p className="text-secondary">Select the template to send broadcast.</p>
                            </div>
                        </div>
                        {(selectedGroup !== null) &&
                            (<div className="card bg-white border-0 text-center mb-4 ">
                                <div className="card-body  ">
                                    <div className="col-12 col-md-auto">
                                        <div className="input-group ">
                                            <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                            <input type="text" className="form-control" placeholder="Search..." value={templateSearch} onChange={handleTemplateSearchChange} />
                                        </div>
                                    </div>
                                    <div className="card border-0 mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12  border-left-dashed">
                                                    <ul className="list-group list-group-flush bg-none">
                                                        <li className="list-group-item text-secondary">
                                                            {(templateList.length > 0) ? (
                                                                templateList.map((item, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-auto">
                                                                            <input type="radio" name='template' id={`template_id_${item._id}`} value={item._id} className='form-check-input' onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setSelectedTemplate(item)
                                                                                console.log('item', item);
                                                                            }} />&nbsp;
                                                                            <label className='cursor-pointer' htmlFor={`template_id_${item._id}`}> {item.name}</label>
                                                                        </div>

                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <div className="row">
                                                                    <p>    No data available.. </p>
                                                                </div>

                                                            )}
                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {templateTotalItems > 0 &&
                                        <Pagination
                                            currentPage={templateCurrentPage}
                                            totalItems={templateTotalItems}
                                            itemsPerPage={templateItemsPerPage}
                                            setCurrentPage={setTemplateCurrentPage}
                                        />
                                    }
                                </div>
                            </div>)
                        }
                    </div>

                    <div className="col-12 col-md-3 col-lg-3">
                        {selectedTemplate &&
                            <div className="row justify-content-center mb-2">
                                <Formik
                                    initialValues={broadcastInitialValues}
                                    validationSchema={broadcastSchema}
                                    onSubmit={handleSubmit}>
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <div className="form-group mb-3 position-relative check-valid">
                                                        <div className="input-group input-group-lg">
                                                            <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                            <div className="form-floating">
                                                                <Field type="text" name="title" className="form-control border-start-0" />
                                                                <label>Title <span className='star'>*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="title" component="div" className="error" />
                                                </div>
                                                <div className="mb-2">
                                                    <div className="form-group mb-3 position-relative check-valid">
                                                        <div className="input-group input-group-lg">
                                                            <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                            <div className="form-floating">
                                                                <Field as="textarea" name="description" className="form-control border-start-0" />
                                                                <label>Description</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="description" component="div" className="error" />
                                                </div>
                                                <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>


                        }
                    </div>

                    <div className="col-12 col-md-3 col-lg-3" style={{height:'100%'}}>
                        {selectedTemplate &&
                            // <div className="col-12 col-md-4">
                                <section className="msger">
                                    <header className="msger-header">
                                        <div className="msger-header-title">
                                            <i className="fas fa-comment-alt"></i> Chat Preview
                                        </div>
                                        <div className="msger-header-options">
                                            <span><i className="fas fa-cog"></i></span>
                                        </div>
                                    </header>

                                    <main className="msger-chat">
                                        <div className="msg left-msg">
                                            <div

                                            ><Icon.PersonBoundingBox className="msg-img" /></div>

                                            <div className="msg-bubble">
                                                <div className="msg-info">
                                                    <div className="msg-info-name">BOT</div>
                                                    {/* <div className="msg-info-time">12:45</div> */}
                                                </div>

                                                <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                                                    {fileImgPreview && (
                                                        <>
                                                            <img src={fileImgPreview} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                            <br />
                                                        </>
                                                    )}

                                                    {fileVdoPreview && (
                                                        <>
                                                            <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                                                <source src={fileVdoPreview} type="video/mp4" />

                                                            </video>
                                                            <br />
                                                        </>
                                                    )}

                                                    {documentPreview && (
                                                        <>
                                                            <iframe
                                                                src={documentPreview}
                                                                style={{ width: '200px', height: '200px' }}
                                                                title="Document Preview"
                                                            />
                                                            <br />
                                                        </>
                                                    )}

                                                    {headerPreview && <><b>{headerPreview}</b><br /><br /></>}
                                                    {bodyPreview && <>{bodyPreview}<br /><br /></>}
                                                    {footerPreview && <>{footerPreview}<br /><br /></>}
                                                    {buttonTypePreview === "quick_reply" && quickReplyPreview.length > 0 && (
                                                        quickReplyPreview.map((button, index) => {
                                                            return (
                                                                <div key={index} >
                                                                    <button type="button" className="msger-send-btn mb-1" >{!button && `Button${index + 1}`}  {button}</button>
                                                                    <br />
                                                                </div>
                                                            )
                                                        })
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </main>

                                    <form className="msger-inputarea">
                                        <input type="text" className="msger-input" placeholder="Enter your message..." disabled />
                                        {/* <button type="submit" className="msger-send-btn" disabled>Send</button> */}
                                    </form>
                                </section>
                            // </div>
                        }

                    </div>

                </div>




            </div>




        </main >
        <Footer />

    </>)
}

export default SendBroadcast;