import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import Header from '../Website/Header';
import Footer from './Footer';
import { getBroadcastCount } from '../../api/broadcastApi';
import { getGroupCount } from '../../api/groupApi';
import { getTemplateCount } from '../../api/templateApi';

import { useAuthContext } from '../../context/AuthContext';

function Dashboard() {
    const { logoutUser } = useAuthContext();

    const [broadcastCount, setBroadcastCount] = useState({});
    const [groupCount, setGroupCount] = useState({});
    const [templateCount, setTemplateCount] = useState({});



    const handleBroadcastCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getBroadcastCount();
            setBroadcastCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }
    const handleGroupCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getGroupCount();
            setGroupCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }
    const handleTemplateCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getTemplateCount();
            setTemplateCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }

    const initializeEmbededSignup = () => {
        window.fbAsyncInit = function () {
            // JavaScript SDK configuration and setup
            window.FB.init({
                appId: '497078262906011', // Facebook App ID
                cookie: true, // enable cookies
                xfbml: true, // parse social plugins on this page
                version: 'v21.0' //Graph API version
            });
        };

        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }


    // Facebook Login with JavaScript SDK
    function launchWhatsAppSignup() {
        // Conversion tracking code
        // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup' });

        // Launch Facebook login
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
                console.log('code', code);

                // The returned code must be transmitted to your backend, 
                // which will perform a server-to-server call from there to our servers for an access token
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '924599896198960', // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
            extras: {
                setup: {
                }
            }
        });
    }
    useEffect(() => {
        initializeEmbededSignup();
        // window.fbAsyncInit = function () {
        //     console.log('step 01');
            
        //     setTimeout(() => {
        //         console.log('step 02',window.FB);
        //         window.FB.init({
        //             appId: '497078262906011', // Facebook App ID
        //             cookie: true, // Enable cookies
        //             xfbml: true, // Parse social plugins on this page
        //             version: 'v21.0' // Graph API version
        //         });
        //     }, 1000);
        //     // JavaScript SDK configuration and setup
            
        // };

        // // Load the JavaScript SDK asynchronously
        // (function (d, s, id) {
        //     console.log('step 03');
        //     var js, fjs = d.getElementsByTagName(s)[0];
        //     if (d.getElementById(id)) return;
        //     js = d.createElement(s); js.id = id;
        //     js.src = "https://connect.facebook.net/en_US/sdk.js";
        //     console.log('step 04');
        //     fjs.parentNode.insertBefore(js, fjs);
        //     console.log('step 05');
        // }(document, 'script', 'facebook-jssdk'));


        handleBroadcastCount()
        handleGroupCount()
        handleTemplateCount()
    }, []);
    return (<>
        <Header />
        <main className="main mainheight">
            <div className="container">
                <div className=" row top-row" >
                    <div className="col-12 col-md-6 col-lg-4">
                        <button onClick={(e)=> launchWhatsAppSignup()}>Apply for whatsapp business API</button>

                        <div className="card border-0 mb-4 theme-blue bg-gradient-theme-light">
                            <div className="card-header ">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className=" rounded bg-light-theme">
                                            <Icon.Broadcast className='dashboradIcon' />

                                        </div>
                                    </div>
                                    <div className="col">
                                        <h6 className="fw-medium mb-0">Broadcast</h6>
                                    </div>
                                    <div className="col-auto">
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-body">
                                <div className="row">
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#sentmoney">
                                            <div className="card-body">
                                                <i className="bi bi-phone h5 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Total Send</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#sentmoney">
                                            <div className="card-body">
                                                <i className="bi bi-bank h5 avatar avatar-40 rounded text-theme mb-2"></i>

                                                <p className="text-secondary small">Not Send</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#sentmoney">
                                            <div className="card-body">
                                                <i className="bi bi-at h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Shedule</p>
                                            </div>
                                        </a>
                                    </div> 
                                </div>
                            </div> */}
                            <div className="card-footer">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="circle-small">
                                            <div id="circleprogressblue1"></div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        {(broadcastCount != '' && broadcastCount != undefined) ? (
                                            <h5 className="mb-0">{broadcastCount.countbroadcast} </h5>

                                        ) : (
                                            <h5 className="mb-0">0 </h5>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card border-0 mb-4 theme-green bg-gradient-theme-light">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className=" rounded bg-light-theme">
                                            <i className="bi bi-receipt-cutoff h5"></i>
                                            <Icon.CollectionFill className='dashboradIcon' />

                                        </div>
                                    </div>
                                    <div className="col">
                                        <h6 className="fw-medium mb-0">Gruop's</h6>
                                    </div>
                                    <div className="col-auto">
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-body">
                                <div className="row">
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#billpay">
                                            <div className="card-body">
                                                <i className="bi bi-lamp h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Create</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#billpay">
                                            <div className="card-body">
                                                <i className="bi bi-router h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Active</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#billpay">
                                            <div className="card-body">
                                                <i className="bi bi-droplet-half h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Inactive</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-footer">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="circle-small">
                                            <div id="circleprogressgreen1"></div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        {(groupCount != '' && groupCount != undefined) ? (
                                            <h5 className="mb-0">{groupCount.countGroup} </h5>

                                        ) : (
                                            <h5 className="mb-0">0 </h5>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card border-0 mb-4 theme-red bg-gradient-theme-light">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className=" rounded bg-light-red">
                                            <Icon.FileEarmarkRichtext className='dashboradIcon' />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h6 className="fw-medium mb-0">Templates</h6>
                                    </div>
                                    <div className="col-auto">
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card-body">
                                <div className="row">
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookmovie">
                                            <div className="card-body">
                                                <i className="bi bi-film h5 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Approved</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookflight">
                                            <div className="card-body">
                                                <i className="bi bi-send h5 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">In-Review</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookbus">
                                            <div className="card-body">
                                                <i className="bi bi-truck h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Rejected</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-footer">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="circle-small">
                                            <div id="circleprogressred1"></div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        {(templateCount != '' && templateCount != undefined) ? (
                                            <h5 className="mb-0">{templateCount.counttemplate} </h5>

                                        ) : (
                                            <h5 className="mb-0">0 </h5>

                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </main>
        <Footer />
    </>)
}

export default Dashboard;