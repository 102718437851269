// src/api/authApi.js
import axiosInstance from './axiosInstance';

/* user signup api
let postData = {
  name: formData.userName,
  mobileNo: formData.mobileNo,
  mobileCode :'+91',
  email : formData.email,
  password : formData.password
}*/
export const registerUser = async (userData) => {
  try {
    const response = await axiosInstance.post('/sing-up', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* user login api
let postData = {
  mobileNo: formData.mobileNo,
  mobileCode :'+91',
  password : formData.password
}*/
export const loginUser = async (userData) => {
  try {
    const response = await axiosInstance.post('/sing-in', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};