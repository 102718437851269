import logo from './logo.svg';
import './App.css';
import './assets/scss/landing.css';

import Routes from './router/Index.js'
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/AuthContext.js';

function App() {
  useEffect(() => {
    document.title = 'Growwup';
  }, []);

  return (
    <>
      <ToastContainer />
      <AuthProvider >
        <Routes />
      </AuthProvider>
    </>

  );
}

export default App;
