// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuthContext} from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { userData } = useAuthContext();
  return userData ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
