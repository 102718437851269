import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import PageNotFound from '../components/Website/PageNotFound';
import Signup from '../components/Auth/Signup';
import Login from '../components/Auth/Login';
import Dashboard from '../components/UserPanel/Dashboard';
import GroupList from '../components/UserPanel/Group/GroupList';
import GroupForm from '../components/UserPanel/Group/GroupForm';
import GroupAddContact from '../components/UserPanel/Group/GroupAddContact';
import GroupContactList from '../components/UserPanel/Group/GroupContactList';
import BroadcastList from '../components/UserPanel/BroadcastQR/BroadcastList';
import CreateBroadcast from '../components/UserPanel/BroadcastQR/CreateBroadcast';
import BroadcastDetail from '../components/UserPanel/BroadcastQR/BroadcastDetail';
import TemplateList from '../components/UserPanel/Template/TemplateList';
import TemplateForm from '../components/UserPanel/Template/TemplateForm';
import TemplateFormEdit from '../components/UserPanel/Template/TemplateFormEdit';
import PrivacyPolicy from '../components/OnlyWebsite/PrivacyPolicy';
import BroadcastTemplateForm from '../components/UserPanel/BroadcastTemplate/BroadcastTemplateForm';
import BroadcastTemplateList from '../components/UserPanel/BroadcastTemplate/BroadcastTemplateList';
import BroadcastTemplateFormEdit from '../components/UserPanel/BroadcastTemplate/BroadcastTemplateFormEdit';
import SendBroadcast from '../components/UserPanel/BroadcastCloud/SendBroadcast';
import BroadcastTemplateFormNew from '../components/UserPanel/BroadcastTemplate/BroadcastTemplateFormButton';

// This component handles the dynamic routing logic
function AppRoutes() {
    const location = useLocation(); // Now inside Router context

    let NewHome = null; // Declare a variable for NewHome component

    // Conditionally lazy load NewHome if the path is '/'
    if (location.pathname === '/') {
        console.log('Loading', location.pathname)
        NewHome = React.lazy(() => import('../components/OnlyWebsite/NewHome'));
    }
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === "/pr") {
            // Dynamically add style2.css
            const style2 = document.createElement('link');
            style2.rel = 'stylesheet';
            style2.href = 'https://growwups.in/mahiSport/style2.css'; // Path to style2.css
            document.head.appendChild(style2);

            // Dynamically add bootstrap.min.css
            const bootstrap = document.createElement('link');
            bootstrap.rel = 'stylesheet';
            bootstrap.href = 'https://growwups.in/mahiSport/bootstrap.min.css'; // Path to bootstrap.min.css
            document.head.appendChild(bootstrap);

            // Clean up when component unmounts or route changes
            return () => {
                if (document.head.contains(style2)) {
                    document.head.removeChild(style2); // Remove style2.css
                }
                if (document.head.contains(bootstrap)) {
                    document.head.removeChild(bootstrap); // Remove bootstrap.min.css
                }
            };
        }
    }, [location.pathname]);
    return (
        <Routes>
            {/* If NewHome is set, render it */}
            {NewHome && (
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <NewHome />
                        </Suspense>
                    }
                />
            )}

            {/* Other routes */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/sign-up" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route
                path="/dashboard"
                element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                }
            />
            <Route
                path="/group"
                element={
                    <PrivateRoute>
                        <GroupList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/add-group"
                element={
                    <PrivateRoute>
                        <GroupForm />
                    </PrivateRoute>
                }
            />
            <Route
                path="/group/add-contact/:groupId"
                element={
                    <PrivateRoute>
                        <GroupAddContact />
                    </PrivateRoute>
                }
            />
            <Route
                path="/group/contact-list/:groupId"
                element={
                    <PrivateRoute>
                        <GroupContactList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/broadcast"
                element={
                    <PrivateRoute>
                        <BroadcastList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/create-broadcast"
                element={
                    <PrivateRoute>
                        <CreateBroadcast />
                    </PrivateRoute>
                }
            />
            <Route
                path="/send-broadcast"
                element={
                    <PrivateRoute>
                        <SendBroadcast />
                    </PrivateRoute>
                }
            />
            <Route
                path="/broadcast-detail/:broadcastId"
                element={
                    <PrivateRoute>
                        <BroadcastDetail />
                    </PrivateRoute>
                }
            />
            <Route
                path="/template"
                element={
                    <PrivateRoute>
                        <TemplateList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/add-template"
                element={
                    <PrivateRoute>
                        <TemplateForm />
                    </PrivateRoute>
                }
            />
            <Route
                path="/template"
                element={
                    <PrivateRoute>
                        <TemplateList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/broadcast-template-list"
                element={
                    <PrivateRoute>
                        <BroadcastTemplateList />
                    </PrivateRoute>
                }
            />
            <Route
                path="/add-broadcast-template"
                element={
                    <PrivateRoute>
                        <BroadcastTemplateForm />
                    </PrivateRoute>
                }
            />

            <Route
                path="/add-broadcast-template01"
                element={
                    <PrivateRoute>
                        <BroadcastTemplateFormNew />
                    </PrivateRoute>
                }
            />
            <Route
                path="/edit-broadcast-template/:templateId"
                element={
                    <PrivateRoute>
                        <BroadcastTemplateFormEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/edit-template/:templateId"
                element={
                    <PrivateRoute>
                        <TemplateFormEdit />
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

// This wraps the router around the AppRoutes component
function Routers() {
    return (
        <BrowserRouter>
            <AppRoutes />
        </BrowserRouter>
    );
}

export default Routers;
