import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useAuthContext } from '../../context/AuthContext';


function Footer() {
    const navigate = useNavigate();
    const { logoutUser } = useAuthContext()
    const handleLogout = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        logoutUser();
    };

    // return (<>Footer</>)

    return (<>


        <footer className="footer-sticky" style={{ marginLeft: "0px" }}>
            <div className="container">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={(e) =>

                            navigate("/")

                        }>
                            <span>
                                <Icon.House />
                                <span className="nav-text">Home</span>
                            </span>
                        </a>
                    </li>

                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={(e) =>

                            navigate("/group")

                        }>
                            <span>
                                <Icon.CollectionFill />
                                <span className="nav-text">Group</span>
                            </span>
                        </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={(e) =>
                            navigate("/broadcast-template-list")
                        }>
                            <span>
                                <Icon.FileEarmarkRichtext />
                                <span className="nav-text">Template</span>
                            </span>
                        </a>
                    </li>
                    <li className="nav-item centerbutton cursor-pointer">
                        <a className="nav-link" onClick={(e) =>
                            navigate("/dashboard")
                        }>
                            <span className="bg-radial-gradient-theme">
                                <Icon.Speedometer2 />
                            </span>
                        </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={(e) =>

                            navigate("/send-broadcast")

                        }>
                            <span>
                                <Icon.Broadcast />
                                <span className="nav-text">Broadcast</span>
                            </span>
                        </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={(e) =>
                            navigate("/broadcast")
                        }>
                            <span>
                                <Icon.BroadcastPin />
                                <span className="nav-text">History</span>
                            </span>
                        </a>
                    </li>

                    <li className="nav-item cursor-pointer">
                        <a className="nav-link" onClick={handleLogout}>
                            <span>
                                <Icon.BoxArrowInDown />
                                <span className="nav-text">LogOut</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </footer >
    </>)
}

export default Footer;