import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { useAuthContext } from '../../context/AuthContext';
import Logo from '../../assets/img/growwuo_logo.png';
const Header = () => {
    const navigate = useNavigate()
    const { logoutUser } = useAuthContext()

    const handleLogout = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        logoutUser();
    };
    return (<>
        <header className="header">

            <nav className="navbar" style={{ "width": "7%" }}>

                <div className="container-fluid" style={{ "width": "80%", "padding": "0px !important" }}>
                    {/* <div className="col-auto"></div> */}

                    <a className="navbar-brand" href="#">
                        <div className="col ps-0 align-self-center" onClick={(e) => { navigate('/') }}>

                            <img src={Logo} className="mx-100" alt="" />
                            {/* <div className="row"> */}
                            {/* <h5 className="fw-normal text-dark">Growwup</h5> */}
                            {/* </div> */}
                        </div>
                    </a>
                    {/* <a className="nav-link" style={{ "marginRight": "15px" }} onClick={handleLogout}>
                        <span>
                            <Icon.BoxArrowInDown />
                            <span className="nav-text" style={{ "marginLeft": "5px" }}>LogOut</span>
                        </span>
                    </a> */}

                </div>
            </nav>
        </header>
    </>)
}

export default Header;