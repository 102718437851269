// src/api/axiosInstance.js
import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const growwupInfo = localStorage.getItem('growwupInfo'); // Adjust this according to where you store the token
    if (growwupInfo) {
      const growwupInfoJson = JSON.parse(growwupInfo)
      if (growwupInfoJson?.token.authToken) {
        config.headers['authorization'] = `Bearer ${growwupInfoJson?.token.authToken}`;
      }

      const decodedData = jwtDecode(growwupInfoJson?.token.authToken);
      const isTokenExpire = dayjs.unix(decodedData.exp).diff(dayjs()) < 1;
      if (!isTokenExpire) {
        return config;
      }

      const decodedRefreshData = jwtDecode(growwupInfoJson?.token.refreshToken);
      const isRefreshTokenExpire = dayjs.unix(decodedRefreshData.exp).diff(dayjs()) < 1;

      if (!isRefreshTokenExpire) {
        try {
          const responceData = await axios.post(process.env.REACT_APP_API_BASE_URL + 'refresh-token', {
            token: growwupInfoJson?.token.refreshToken,
          });
          let tokenInfo = responceData.data.token;
          localStorage.setItem('growwupInfo', JSON.stringify({ token: tokenInfo }));
          config.headers['authorization'] = `Bearer ${tokenInfo.authToken}`;
        } catch (err) {
        }
      }
    }


    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
