import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Header from '../../Website/Header';
import Footer from '../Footer';

import { useNavigate, useParams } from 'react-router-dom';
import { deleteGroupContact, getGroupContactList, getGroupDetails } from '../../../api/groupApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Swal from 'sweetalert2';
import Loader from '../../common/Loader';
import Pagination from '../../common/Pagination';

function GroupContactList() {
    const navigate = useNavigate();
    const [groupInfo, setGroupInfo] = useState(null)
    const [groupContactList, setGroupContactList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logoutUser } = useAuthContext();
    const { groupId } = useParams();

    //search and pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(2);
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')
    const [timerId, setTimerId] = useState(null);

    const handleGroupContactList = async () => {
        setLoading(true);
        try {

            let postData = {
                id: groupId,
                limit: itemsPerPage,
                skip: (currentPage - 1) * itemsPerPage,
                name: finalSearchText
            }
            const groupDetailsPromise = getGroupDetails(groupId); // Replace with your actual API call for group details
            const groupContactListPromise = getGroupContactList(postData);
            // Wait for both API calls to complete
            const [groupDetailsResponse, groupContactListResponse] = await Promise.all([groupDetailsPromise, groupContactListPromise]);
           
            // Manage group Info responce
            if (groupDetailsResponse?.data[0]) {
                setGroupInfo(groupDetailsResponse?.data[0])
            }
            // manage contact list responce
            if (groupContactListResponse?.data?.data) {
                setTotalItems(groupContactListResponse.data.count)
                setGroupContactList(groupContactListResponse.data.data);
            } else {
                setTotalItems(0)
                setGroupContactList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Group list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }


    const handleGroupContactDelete = async (item) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Want to delete <b>${item.contactId.name}</b> from this group.`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",

        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    await deleteGroupContact(item._id);
                    handleGroupContactList();
                    Swal.fire({
                        title: "Deleted!",
                        html: `Contact <b>${item.contactId.name}</b> deleted successfuly!.`,
                        icon: "success"
                    });

                } catch (error) {
                    if (error.response?.status == 401) {
                        logoutUser();
                    } else {
                        const errorMessage = error.response?.data?.ErrorMessage || 'Delete group failed';
                        toast.error(errorMessage);
                    }
                } finally {
                    setLoading(false);
                }
            }
        });
    }

    // Event handler for input change
    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);

        // Debouncing logic
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTimerId(newTimerId);
    };

    useEffect(() => {
        handleGroupContactList();
    }, [currentPage, finalSearchText])

    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">


                <h5 className="title">Group Contact List</h5>
                <div className="col mb-2" style={{ float: 'right' }}>
                    <button onClick={(e) => navigate('/group')} className="btn btn-theme btn-md" >Back</button>
                </div>

                <div className="card border-0 mb-4">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-auto">
                                <i className="bi bi-shop h5 avatar avatar-40 bg-light-theme rounded"></i>
                            </div>
                            <div className="col mb-2">
                                <button onClick={(e) => navigate(`/group/add-contact/${groupId}`)} className="btn btn-theme btn-md" >Add Contact</button>
                            </div>
                            <div className="col mb-2">
                                <b>Group Name: </b>{groupInfo?.name} <br />
                                <b>Total Contacts: </b>{groupInfo?.totalContact}
                            </div>


                            <div className="col-12 col-md-auto">
                                <div className="input-group ">
                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search..." value={search} onChange={handleSearchChange} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-body p-0">
                        <table className="table footable" data-show-toggle="true">
                            <thead>
                                <tr className="text-muted">
                                    <th>Sr No.</th>
                                    <th>Contact Name</th>
                                    <th>Mobile Number</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {(groupContactList?.length > 0) ? (
                                    groupContactList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.contactId.name}</td>
                                            <td>{item.contactId.mobileNo}</td>
                                            <td>
                                                <Icon.Trash size={18} color="red" className='cursor-pointer' onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleGroupContactDelete(item);
                                                }} />
                                            </td>
                                        </tr>

                                    ))
                                ) : (
                                    <tr key="0" >
                                        <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                    </tr>
                                )}



                            </tbody>
                        </table>
                        {totalItems > 0 &&
                            <Pagination
                                currentPage={currentPage}
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    </div>

                </div>


            </div>


        </main>
        <Footer />

    </>)
}

export default GroupContactList;