import { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState(
        localStorage.getItem('growwupInfo') && JSON.parse(localStorage.getItem('growwupInfo')).token.authToken ? jwtDecode(JSON.parse(localStorage.getItem('growwupInfo')).token.authToken) : null
    );
    const storeUserDataInContext = (userToken) => {
        const decodedData = jwtDecode(userToken.authToken);
        setUserData(decodedData);
    }

    const logoutUser = () => {
        // localStorage.removeItem('growwupInfo');
        localStorage.clear();

        setUserData(null);
    }
    const contextValues = {
        userData,
        storeUserDataInContext,
        logoutUser
    }
    return (
        <AuthContext.Provider value={contextValues}>
            {children}
        </AuthContext.Provider>
    );
}