import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';

import Header from '../../Website/Header';
import Footer from '../Footer';

import { useNavigate } from 'react-router-dom';
import { deleteBroadcastTemplate, getBroadcastTemplateList, checkTemplateStatus } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loader from '../../common/Loader';
import { formateDateTime } from '../../../utils/dateTimeManage'
import Pagination from '../../common/Pagination';

function BroadcastTemplateList() {
    const navigate = useNavigate();
    const [templateList, setTemplateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { logoutUser } = useAuthContext();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [finalSearchText, setFinalSearchText] = useState('')
    const [timerId, setTimerId] = useState(null);

    const handleTemplateList = async () => {
        setLoading(true);
        try {
            let postData = {
                limit: itemsPerPage,
                skip: (currentPage - 1) * itemsPerPage,
                search: finalSearchText
            }
            const responceData = await getBroadcastTemplateList(postData);

            if (responceData?.data) {
                setTotalItems(responceData.count)
                setTemplateList(responceData.data);
            } else {
                setTotalItems(0)
                setTemplateList([]);
            }
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Template list failed';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleTemplateDelete = async (item) => {
        Swal.fire({
            title: "Are you sure?",
            html: `Want to delete <b>${item.name}</b> template.`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",

        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    await deleteBroadcastTemplate(item._id);
                    handleTemplateList();
                    Swal.fire({
                        title: "Deleted!",
                        html: `<b>${item.name}</b> template deleted successfuly!.`,
                        icon: "success"
                    });

                } catch (error) {
                    if (error.response?.status == 401) {
                        logoutUser();
                    } else {
                        const errorMessage = error.response?.data?.ErrorMessage || 'Delete group failed';
                        toast.error(errorMessage);
                    }
                } finally {
                    setLoading(false);
                }
            }
        });
    }

    const checkStatus = async (item) => {
        setLoading(true);
        try {
            let responceData =  await checkTemplateStatus(item._id);
            handleTemplateList();
            let status= responceData.data.status
            Swal.fire({
                html: `<b>${item.name}</b> template current status is ${status}`,
                // icon: "success"
            });

        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Check status failed';
                toast.error(errorMessage);
            }
        }
    }


    // Event handler for input change
    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);

        // Debouncing logic
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setFinalSearchText(text);
        }, 1000); // Adjust the debounce time as needed (e.g., 500ms)
        // Update timerId state
        setTimerId(newTimerId);
    };

    useEffect(() => {
        handleTemplateList();
    }, [currentPage, finalSearchText])

    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">


                <h5 className="title">Manage Templates</h5>

                <div className="card border-0 mb-4">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-auto">
                                <i className="bi bi-shop h5 avatar avatar-40 bg-light-theme rounded"></i>
                            </div>
                            <div className="col mb-2">
                                {/* <button style={{marginRight:'4px'}} onClick={(e) => navigate('/add-template')} className="btn btn-theme btn-md" >Add Template</button> */}
                                <button onClick={(e) => navigate('/add-broadcast-template')} className="btn btn-theme btn-md" >Add Broadcast Template</button>
                            </div>


                            <div className="col-12 col-md-auto">
                                <div className="input-group ">
                                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                                    <input type="text" className="form-control" placeholder="Search..." value={search} onChange={handleSearchChange} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card-body p-0">
                        <table className="table footable" data-show-toggle="true">
                            <thead>
                                <tr className="text-muted">
                                    <th>Sr No.</th>
                                    <th>Template Name</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                    <th>Created Date</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {(templateList.length > 0) ? (
                                    templateList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.category}</td>
                                            <td> <span  style={{ color: item.status == "PENDING" || item.status == "REVIEW" ? 'orange' : item.status == "APPROVED" ? 'green' : 'red' }}>{item.status}</span>
                                                {(item.status !== "APPROVED" && item.status !== "REJECTED") && <button style={{marginLeft:'5px'}} onClick={(e) => checkStatus(item)} className="btn btn-theme btn-sm " >Check Status</button>}


                                            </td>
                                            <td>{formateDateTime(item.createdAt)}</td>
                                            <td>
                                                { (item.status === "APPROVED" || item.status === "REJECTED"  || item.status === "PAUSED ") &&  <Icon.Pencil size={18} color="blue" className='cursor-pointer' onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/edit-broadcast-template/${item._id}`)
                                                }} />}

                                                <Icon.Trash size={18} color="red" className='cursor-pointer' onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleTemplateDelete(item);
                                                }} />


                                            </td>
                                        </tr>

                                    ))
                                ) : (
                                    <tr key="0" >
                                        <th colSpan="20" style={{ textAlign: 'center' }}>No data available..</th>
                                    </tr>
                                )}



                            </tbody>
                        </table>
                        {totalItems > 0 &&
                            <Pagination
                                currentPage={currentPage}
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    </div>

                </div>


            </div>


        </main>
        <Footer />

    </>)
}

export default BroadcastTemplateList;