import React, { useEffect, useState } from 'react';

const Footer = () => {

    return (<>
        <div className="col-12 mt-auto px-0">
            <footer className="footer text-white">
                <div className="container-fluid text-center">
                    <p className="text-secondary small">Copyright @2022, Designed by <a href="https://maxartkiller.com/" target="_blank">Growwup</a></p>
                    {/* <ul className="nav justify-content-center small">
                        <li className="nav-item"><a className="nav-link" href="help-center.html">Help</a></li>
                        <li className="nav-item">|</li>
                        <li className="nav-item"><a className="nav-link" href="terms-of-use.html">Terms of Use</a></li>
                        <li className="nav-item">|</li>
                        <li className="nav-item"><a className="nav-link" href="privacy-policy.html">Privacy Policy</a></li>
                    </ul> */}
                </div>
            </footer>
        </div>
    </>)
}

export default Footer;