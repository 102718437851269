import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { templateInitialValues, templateSchema } from '../../../utils/validationSchema';
import { getTemplateInfoApi, updateTemplate } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';


function TemplateFormEdit() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const { templateId } = useParams();
    const fileInputRef = useRef(null);
    const [isRemoveImage, setIsRemoveImage] = useState(0)
    const [filePreview, setFilePreview] = useState(null);
    const [initialValues, setInitialValues] = useState(templateInitialValues);
    const [descriptionPreview, setDescriptionPreview] = useState('');

    const navigate = useNavigate();
    const getTemplateInfo = async () => {
        setLoading(true);
        try {
            let responceData = await getTemplateInfoApi(templateId);
            if (responceData?.data) {
                setDescriptionPreview(responceData?.data.description)
                setInitialValues({
                    templateName: responceData?.data.name,
                    description: responceData?.data.description
                })
                console.log('img:....', process.env.REACT_APP_API_IMG_URL + responceData?.data.imageUrl);
                if (responceData?.data.imageUrl && responceData?.data.imageUrl != "") {
                    setFilePreview(process.env.REACT_APP_API_IMG_URL + responceData?.data.imageUrl);
                }
            } else {
                setInitialValues({
                    templateName: '',
                    description: ''
                })
            }

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Get template info failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (htmlFormData) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', htmlFormData.templateName);
            formData.append('description', htmlFormData.description);
            formData.append('createdby', userData._id);
            formData.append('isRemoveImage', isRemoveImage);
            
            if (htmlFormData.file) {
                formData.append('file', htmlFormData.file);
            }
            let responceData = await updateTemplate(formData, templateId);
            toast.success('Template updated successful!');
            // navigate('/template')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Update template failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, setFieldValue) => {
        const file = event.currentTarget.files[0];
        setFieldValue("file", file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFilePreview(null);
        }
    };

    const removeFile = (setFieldValue) => {
        setFieldValue("file", null);
        setFilePreview(null);
        setIsRemoveImage(1)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };

    useEffect(() => {
        getTemplateInfo()
    }, [])
    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">

                <h5 className="title">Edit Template</h5>
                <div className="col mb-2" style={{ float: 'right' }}>
                    <button onClick={(e) => navigate('/template')} className="btn btn-theme btn-md" >Back</button>
                </div>
                <div className="container">
                    <div className="row mb-4 py-2">
                        <div className="col text-center">
                            <h4>Update template for send <span className="text-gradient">Broadcast</span> to users</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={templateSchema}
                                enableReinitialize
                                onSubmit={handleSubmit}>
                                {({ isSubmitting, setFieldValue }) => (
                                    <Form>
                                        <div className="col-12 ">
                                            <div className="mb-2">
                                                <div className="form-template mb-3 position-relative check-valid">
                                                    <div className="input-template input-template-lg">
                                                        <div className="form-floating">
                                                            <Field type="text" name="templateName" className="form-control border-start-0" />
                                                            <label>Template Name <span className='star'>*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="templateName" component="div" className="error" />
                                            </div>

                                            <div className="col-12 col-md-12 mb-2">
                                                <div className="form-template mb-3 position-relative check-valid">
                                                    <div className="input-template input-template-lg">
                                                        <div className="form-floating">

                                                            <Field as="textarea" name="description" className="form-control border-start-0 h-auto" rows="10"
                                                                onChange={(e) => {
                                                                    setFieldValue('description', e.target.value);
                                                                    setDescriptionPreview(e.target.value);
                                                                }}
                                                            />
                                                            <label>Description <span className='star'>*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="description" component="div" className="error" />
                                            </div>
                                            <div className="col-12 col-md-12 mb-2">
                                                <div className="form-template mb-3 position-relative check-valid">
                                                    <div className="input-template input-template-lg">
                                                        <div className="form-floating">
                                                            <input
                                                                id="file"
                                                                name="file"
                                                                type="file"
                                                                className="form-control"
                                                                onChange={(event) => handleFileChange(event, setFieldValue)}
                                                                ref={fileInputRef} // Assign reference to file input
                                                            />
                                                            <label>
                                                                Upload Image <span className="star">*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="file" component="div" className="error" />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <button style={{ height: '36px', marginRight: '30px' }} className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                                {filePreview && (
                                                    <div className="file-preview" style={{ position: 'relative' }}>
                                                        <img src={filePreview} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                        <span style={{ cursor: 'pointer', position: 'absolute', left: '182px', background: 'red', padding: '5px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="col-12 col-md-6">
                            <section className="msger">
                                <header className="msger-header">
                                    <div className="msger-header-title">
                                        <i className="fas fa-comment-alt"></i> Chat Preview
                                    </div>
                                    <div className="msger-header-options">
                                        <span><i className="fas fa-cog"></i></span>
                                    </div>
                                </header>

                                <main className="msger-chat">
                                    <div className="msg left-msg">
                                        <div

                                        ><Icon.PersonBoundingBox className="msg-img" /></div>

                                        <div className="msg-bubble">
                                            <div className="msg-info">
                                                <div className="msg-info-name">BOT</div>
                                                {/* <div className="msg-info-time">12:45</div> */}
                                            </div>

                                            <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                                                {filePreview && (
                                                    <>
                                                        <img src={filePreview} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                                        <br />
                                                    </>
                                                )}
                                                {descriptionPreview}
                                            </div>
                                        </div>
                                    </div>
                                </main>

                                <form className="msger-inputarea">
                                    <input type="text" className="msger-input" placeholder="Enter your message..." disabled />
                                    <button type="submit" className="msger-send-btn" disabled>Send</button>
                                </form>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className=' className="col-6 col-md-6"'>

                    </div>
                    <div className='className="col-6 col-md-6"'>

                    </div>
                </div>

            </div>
        </main >
        <Footer />

    </>)
}

export default TemplateFormEdit;