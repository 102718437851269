// src/api/authApi.js
import axiosInstance from './axiosInstance';

/* Get users details */

export const getUserDetails = async () => {
    try {
        const response = await axiosInstance.get('/user-details');
        return response.data;
    } catch (error) {
        throw error;
    }
};