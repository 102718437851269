import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { templateInitialValuesNew, templateSchemaNew } from '../../../utils/validationSchema';
import { addBroadcastTemplate } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import languages from "./languages.json";
import TemplatePreview from '../../common/TemplatePreview';


function BroadcastTemplateFormNew() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const fileInputRef = useRef(null); // Reference for file input
    const [templatePreview, setTemplatePreview] = useState({ source: 'BroadcastTemplateForm' })
    const [btnLimit, setBtnLimit] = useState({
        //limit
        templateMaxButtonLimit: 10,
        quickReplyMaxBtnLimit: 10,
        visitWebsiteMaxBtnLimit: 2,
        callPhoneNumberMaxBtnLimit: 1,
        copyOfferCodeMaxBtnLimit: 1,
        // can add, we conditionaly minus  this as different type of buttons added
        templateAddedBtn: 0,
        quickReplyAddBtn: 10,
        visitWebsiteAddBtn: 2,
        callPhoneNumberAddBtn: 1,
        copyOfferCodeAddBtn:1,
    });

    const [selectedButtonType, setSelectedButtonType] = useState([]);


    const navigate = useNavigate();
    const handleSubmit = async (htmlFormData) => {
        // console.log('htmlFormData', htmlFormData);
        // return
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', htmlFormData.templateName);
            formData.append('templateType', 'PreApproved');
            formData.append('category', htmlFormData.category);
            formData.append('language', htmlFormData.language);
            formData.append('headerType', htmlFormData.header_type);
            formData.append('headerText', htmlFormData.header_text);

            if (htmlFormData.header_type === "IMAGE") {
                formData.append('headerFile', htmlFormData.header_image);

            } else if (htmlFormData.header_type === "VIDEO") {
                formData.append('headerFile', htmlFormData.header_video);

            } else if (htmlFormData.header_type === "DOCUMENT") {
                formData.append('headerFile', htmlFormData.header_document);
            }
            formData.append('body', htmlFormData.body);
            formData.append('footer', htmlFormData.footer);
            formData.append('createdby', userData._id);

            if(htmlFormData.buttonType?.includes("quickReply")){
                formData.append('quickReplies', JSON.stringify(htmlFormData.quickReplies));
            }
            if(htmlFormData.buttonType?.includes("visitWebsite")){
                formData.append('visitWebsiteButtons', JSON.stringify(htmlFormData.visitWebsiteButtons));
            }
            if(htmlFormData.buttonType?.includes("callPhoneNumber")){
                formData.append('callPhoneNumberButtons', JSON.stringify(htmlFormData.callPhoneNumberButtons));
            }
            if(htmlFormData.buttonType?.includes("copyOfferCode")){
                formData.append('copyOfferCodeButtons', JSON.stringify(htmlFormData.copyOfferCodeButtons));
            }

            let responceData = await addBroadcastTemplate(formData);
            toast.success('Template created successful!');
            navigate('/broadcast-template-list')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create template failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, setFieldValue, fileId) => {
        const file = event.currentTarget.files[0];
        setFieldValue(fileId, file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTemplatePreview(prevState => ({
                    ...prevState,
                    headerFile: reader.result,
                }))
            };
            reader.readAsDataURL(file);
        } else {
            setTemplatePreview(prevState => ({
                ...prevState,
                headerFile: null,
            }))
        }
    };

    const removeFile = (setFieldValue) => {
        setFieldValue("file", null);
        setTemplatePreview(prevState => ({
            ...prevState,
            headerFile: null,
        }))
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };

    useEffect(() => {
        // delete key from prev if button type unselected
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('quickReply')) {
            delete templatePreview['quickReply']
        }
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('visitWebsite')) {
            delete templatePreview['visitWebsite']
        }
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('callPhoneNumber')) {
            delete templatePreview['callPhoneNumber']
        }
    }, [templatePreview.buttonType])

    useEffect(() => {
        let quickReplyLength = templatePreview.quickReply ? templatePreview.quickReply.length : 0;
        let visitWebsiteLength = templatePreview.visitWebsite ? templatePreview.visitWebsite.length : 0;
        let callPhoneNumberLength = templatePreview.callPhoneNumber ? templatePreview.callPhoneNumber.length : 0;
        let copyOfferCodeLength = templatePreview.copyOfferCode ? templatePreview.copyOfferCode.length : 0;
        let templateAddedBtn = quickReplyLength + visitWebsiteLength + callPhoneNumberLength + copyOfferCodeLength;

        // calculate for quickReplyAddBtn
        let quickReplyAddBtn = btnLimit.quickReplyMaxBtnLimit - templateAddedBtn;

        // calculate for visitWebsiteAddBtn
        let visitWebsiteAddBtn = btnLimit.visitWebsiteMaxBtnLimit - visitWebsiteLength;
        if (visitWebsiteAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            visitWebsiteAddBtn = 0
        }

        // calculate for callPhoneNumberAddBtn
        let callPhoneNumberAddBtn = btnLimit.callPhoneNumberMaxBtnLimit - callPhoneNumberLength;
        if (callPhoneNumberAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            callPhoneNumberAddBtn = 0
        }

        // calculate for copyOfferCodeAddBtn
        let copyOfferCodeAddBtn = btnLimit.copyOfferCodeMaxBtnLimit - copyOfferCodeLength;
        if (copyOfferCodeAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            copyOfferCodeAddBtn = 0
        }

        setBtnLimit(prev => ({
            ...prev,
            templateAddedBtn:templateAddedBtn,
            quickReplyAddBtn: quickReplyAddBtn,
            visitWebsiteAddBtn: visitWebsiteAddBtn,
            callPhoneNumberAddBtn: callPhoneNumberAddBtn,
            copyOfferCodeAddBtn: copyOfferCodeAddBtn
        }))

    }, [templatePreview.quickReply, templatePreview.visitWebsite, templatePreview.callPhoneNumber, templatePreview.copyOfferCode])

    // useEffect(()=>{
    //     console.log('//////////////////////////////////////////////////');
    //     console.log('btnLimit',btnLimit);
    //     console.log('templatePreview',templatePreview);
    //     console.log('')
        
    // })
    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight">
            <div className="container">
                <div className='headering-back-div'>
                    <h5 className="title">Add Broadcast Template</h5>
                    {/* <div className="row mb-4 py-2">
                        <div className="col text-center"> */}
                    <h4>Create template for send <span className="text-gradient">Broadcast</span> to users</h4>
                    {/* </div>
                    </div> */}
                    {/* <div className="col mb-2" style={{ float: 'right' }}> */}
                    <button onClick={(e) => navigate('/broadcast-template-list')} className="btn btn-theme btn-md" style={{ "height": "35pxgut " }} >Back</button>
                    {/* </div> */}
                </div>
                <div className="container">

                    <div className="row">
                        {/* Template Form section start */}
                        <div className="col-12 col-md-8">
                            <Formik
                                initialValues={templateInitialValuesNew}
                                validationSchema={templateSchemaNew}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, setFieldValue, values }) => (

                                    <Form>
                                        <div className="col-12 ">
                                            
                                        <div className="col-12 ">
                                                <div className="row mb-4">
                                                    <div className="col-12 col-md-6 col-lg-5 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field type="text" name="templateName" className="form-control border-start-0" />
                                                                    <label>Template Name <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="templateName" component="div" className="error" />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-3 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="category" className="form-control">
                                                                        <option value='' >Select</option>
                                                                        <option value="MARKETING">Marketing</option>
                                                                        <option value='UTILITY'>Utility</option>

                                                                    </Field>
                                                                    <label>Category <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="category" component="div" className="error" />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 ">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="language" className="form-control">
                                                                        <option value='' >Select</option>
                                                                        {languages.map((languageObj, index) => {
                                                                            return <option key={`lang_${index}`} value={languageObj.Code}>{languageObj.Language}</option>
                                                                        })}

                                                                    </Field>
                                                                    <label>Language <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="language" component="div" className="error" />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Header section  start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="select" name="header_type"
                                                                    onChange={(e) => {
                                                                        setFieldValue("header_type", e.target.value);
                                                                        // Clear previews when changing header type
                                                                        setTemplatePreview(prevState => ({
                                                                            ...prevState,
                                                                            headerType: e.target.value,
                                                                            headerText: null,
                                                                            headerFile: null,
                                                                        }))
                                                                    }}
                                                                    className="form-control">
                                                                    <option value='' >None</option>
                                                                    <option value='TEXT' >Text</option>
                                                                    <option value='IMAGE' >Image</option>
                                                                    <option value='VIDEO' >Video</option>
                                                                    <option value='DOCUMENT' >Document</option>
                                                                </Field>
                                                                <label>Header (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="header_type" component="div" className="error" />
                                                </div>
                                            </div>
                                            {values.header_type !== '' && (
                                                <div className="col-12 ">
                                                    {values.header_type === 'TEXT' && (
                                                        <div className="mb-2">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field type="text" name="header_text"
                                                                            onChange={(e) => {
                                                                                setFieldValue('header_text', e.target.value);
                                                                                setTemplatePreview(prevState => ({
                                                                                    ...prevState,
                                                                                    headerText: e.target.value
                                                                                }))
                                                                            }}
                                                                            className="form-control border-start-0" />
                                                                        <label>Header Text <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="header_text" component="div" className="error" />
                                                        </div>
                                                    )}

                                                    {values.header_type === 'IMAGE' && (
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <div className="mb-2">
                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                        <div className="input-template input-template-lg">
                                                                            <div className="form-floating">
                                                                                <input
                                                                                    id="header_image"
                                                                                    name="header_image"
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    onChange={(event) => handleFileChange(event, setFieldValue, 'header_image')}
                                                                                    ref={fileInputRef} // Assign reference to file input
                                                                                    accept=".jpg, .jpeg, .png"
                                                                                />
                                                                                <label>Header Image <span className='star'>*</span> (.jpeg, .jpg, .png)</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorMessage name="header_image" component="div" className="error" />
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                {templatePreview.headerFile !== null && (
                                                                    <div className="file-preview" style={{ position: 'relative' }}>
                                                                        <img src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: 'auto', marginBottom: '10px' }} />
                                                                        <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {values.header_type === 'VIDEO' && (
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <div className="mb-2">
                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                        <div className="input-template input-template-lg">
                                                                            <div className="form-floating">
                                                                                <input
                                                                                    id="header_video"
                                                                                    name="header_video"
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    onChange={(event) => handleFileChange(event, setFieldValue, 'header_video')}
                                                                                    ref={fileInputRef} // Assign reference to file input
                                                                                    accept=".mp4"
                                                                                />
                                                                                <label>Header Video <span className='star'>*</span> (.mp4)</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorMessage name="header_video" component="div" className="error" />
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                {templatePreview.headerFile !== null && (
                                                                    <div className="file-preview" style={{ position: 'relative' }}>
                                                                        <video controls style={{ width: '200px', height: 'auto', marginBottom: '10px' }}>
                                                                            <source src={templatePreview.headerFile} type="video/mp4" />
                                                                        </video>
                                                                        <span style={{ cursor: 'pointer', position: 'absolute', left: '188px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}


                                                    {values.header_type === 'DOCUMENT' && (
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <div className="mb-2">
                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                        <div className="input-template input-template-lg">
                                                                            <div className="form-floating">
                                                                                <input
                                                                                    id="header_document"
                                                                                    name="header_document"
                                                                                    type="file"
                                                                                    className="form-control"
                                                                                    onChange={(event) => handleFileChange(event, setFieldValue, 'header_document')}
                                                                                    ref={fileInputRef} // Assign reference to file input
                                                                                    accept=".pdf"
                                                                                />
                                                                                <label>Header Document  <span className='star'>*</span> (.pdf)</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorMessage name="header_document" component="div" className="error" />
                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                {templatePreview.headerFile !== null && (
                                                                    <div className="file-preview" style={{ position: 'relative' }}>
                                                                        <iframe src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: '60px', marginBottom: '10px' }} />
                                                                        <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}



                                                </div>)}
                                            {/* Header section end */}

                                            {/* Body Section start */}
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="textarea" name="body" className="form-control border-start-0 h-auto" rows="5"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body', e.target.value);
                                                                        setTemplatePreview(prevState => ({
                                                                            ...prevState,
                                                                            body: e.target.value,
                                                                        }))
                                                                    }}
                                                                />
                                                                <label>Body <span className='star'>*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="body" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* Body section end */}

                                            {/* Footer Section start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field type="text" name="footer"
                                                                    onChange={(e) => {
                                                                        setFieldValue('footer', e.target.value);
                                                                        setTemplatePreview(prevState => ({
                                                                            ...prevState,
                                                                            footer: e.target.value,
                                                                        }))
                                                                    }}
                                                                    className="form-control border-start-0" />
                                                                <label>Footer (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="footer" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* Footer section end */}

                                            {/* Button section start */}
                                            <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="select"
                                                                    onChange={(e) => {

                                                                        const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
                                                                        // set preview initial value
                                                                        selectedValues.map(optionSelected => {
                                                                            if (!templatePreview[optionSelected]) {
                                                                                if (optionSelected === 'quickReply') {
                                                                                    setTemplatePreview(prev => ({
                                                                                        ...prev, quickReply: [{
                                                                                            "replyButton": ""
                                                                                        }]
                                                                                    }))
                                                                                } else if (optionSelected === 'visitWebsite') {
                                                                                    setTemplatePreview(prev => ({
                                                                                        ...prev, visitWebsite: [{
                                                                                            "buttonText": "",
                                                                                            "websiteUrl": "",
                                                                                        }]
                                                                                    }))
                                                                                } else if (optionSelected === 'callPhoneNumber') {
                                                                                    setTemplatePreview(prev => ({
                                                                                        ...prev, callPhoneNumber: [{
                                                                                            "buttonText": "",
                                                                                            "countryCode": "",
                                                                                            "phoneNumber": ""
                                                                                        }]
                                                                                    }))
                                                                                } else if (optionSelected === 'copyOfferCode') {
                                                                                    setTemplatePreview(prev => ({
                                                                                        ...prev, copyOfferCode: [{
                                                                                            "buttonText": "Copy offer code",
                                                                                            "offerCode": ""
                                                                                        }]
                                                                                    }))
                                                                                }
                                                                            }
                                                                        })
                                                                        if (e.target.value === "call_to_action" && 0) {
                                                                            setFieldValue('buttonType', [e.target.value])
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                buttonType: [e.target.value],
                                                                            }))
                                                                        } else {
                                                                            setFieldValue('buttonType', selectedValues);
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                buttonType: selectedValues,
                                                                            }))
                                                                        }

                                                                        // Detect newly unselected items
                                                                        const removed = selectedButtonType.filter((value) => !selectedValues.includes(value));
                                                                        if (removed.includes("quickReply")) {
                                                                            setFieldValue('quickReplies', [{ "replyButton": "" }]);
                                                                        }
                                                                        if (removed.includes("visitWebsite")) {
                                                                            setFieldValue('visitWebsiteButtons', [{ "buttonText": "", "websiteUrl": "" }]);
                                                                        }
                                                                        if (removed.includes("callPhoneNumber")) {
                                                                            setFieldValue('callPhoneNumberButtons', [{ "buttonText": "", "countryCode": "", "phoneNumber": "" }]);
                                                                        }
                                                                        if (removed.includes("copyOfferCode")) {
                                                                            setFieldValue('copyOfferCodeButtons', [{ "buttonText": "", "offerCode": "" }]);
                                                                        }

                                                                        // Update the selected values state
                                                                        setSelectedButtonType(selectedValues);

                                                                    }}
                                                                    multiple
                                                                    name="buttonType" className="form-control">
                                                                    <option value='' >None</option>
                                                                    {/* <option value="call_to_action">Call to Action</option> */}
                                                                    <option value='quickReply'>Quick Reply (Max 10)</option>
                                                                    <option value='visitWebsite'>Visit Website (Max 2)</option>
                                                                    <option value='callPhoneNumber'>Call Phone Number (Max 1)</option>
                                                                    <option value='copyOfferCode'>Copy offer code (Max 1)</option>


                                                                </Field>
                                                                {/* <label>Button (Optional)</label> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="buttonType" component="div" className="error" />
                                                </div>
                                            </div>
                                            {values.buttonType.includes('quickReply') && (
                                                <FieldArray name="quickReplies">
                                                    {({ insert, remove, push }) => (
                                                        <div className="col-12 " style={{backgroundColor:'lightblue'}}>
                                                            <b>Quick Reply.......</b>
                                                            {values.quickReplies && values.quickReplies.length > 0
                                                                ? values.quickReplies.map((reply, index) => (
                                                                    <div className="row mb-4" key={index}>
                                                                        <div className="col-12 col-md-8">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                // name={`quickReplies.${index}`}
                                                                                                name={`quickReplies[${index}].replyButton`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`quickReplies[${index}].replyButton`, e.target.value);

                                                                                                    // Update the quickReplyPreview array
                                                                                                    const newPreview = [...templatePreview.quickReply];
                                                                                                    newPreview[index] = { replyButton: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, quickReply: newPreview }));
                                                                                                }}
                                                                                                className="form-control border-start-0"
                                                                                                maxLength="25"
                                                                                            />
                                                                                            <label>Button{index + 1}<span className='star'>*</span></label>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`quickReplies[${index}].replyButton`} component="div" className="error" />
                                                                            </div>
                                                                            {`${reply['replyButton'].length}/25`}<br />

                                                                        </div>
                                                                        {values.quickReplies.length > 1 && (
                                                                            <div className="col-12 col-md-4 ">
                                                                                <button className="btn btn-sm btn-danger " type="button"
                                                                                    onClick={() => {
                                                                                        remove(index);

                                                                                        // Update the quickReplyPreview array
                                                                                        const newPreview = [...templatePreview.quickReply];
                                                                                        let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                        setTemplatePreview(prev => ({ ...prev, quickReply: newPreviewData }));

                                                                                    }} >X</button>
                                                                            </div>
                                                                        )}

                                                                        {values.quickReplies.length - 1 === index && btnLimit.quickReplyAddBtn > 0 && (
                                                                            <button className="btn btn-sm btn-info" type="button"
                                                                                onClick={() => {
                                                                                    push({ replyButton: '' })
                                                                                    setTemplatePreview(prev => ({ ...prev, quickReply: [...prev.quickReply, { replyButton: '' }] }));

                                                                                }}>Add Quick Reply +{btnLimit.quickReplyAddBtn}</button>
                                                                        )}


                                                                    </div>
                                                                ))
                                                                : null}


                                                        </div>
                                                    )}
                                                </FieldArray>
                                            )}

                                            {values.buttonType.includes('visitWebsite') && (
                                                <FieldArray name="visitWebsiteButtons">
                                                    {({ insert, remove, push }) => (
                                                        <div className="col-12 " style={{backgroundColor:'lightcoral'}}>
                                                            <b>Visit Website.....</b>
                                                            {values.visitWebsiteButtons && values.visitWebsiteButtons.length > 0
                                                                ? values.visitWebsiteButtons.map((visitWebsiteButton, index) => (
                                                                    <div className="row mb-4" key={index}>
                                                                        <div className="col-12 col-md-5">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`visitWebsiteButtons[${index}].buttonText`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`visitWebsiteButtons[${index}].buttonText`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.visitWebsite];
                                                                                                    newPreview[index] = { ...newPreview[index], buttonText: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, visitWebsite: newPreview }));

                                                                                                }}
                                                                                                className="form-control border-start-0"
                                                                                                maxLength="25"
                                                                                            />
                                                                                            <label>Button Text {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${visitWebsiteButton['buttonText'].length}/25`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`visitWebsiteButtons[${index}].buttonText`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-5">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`visitWebsiteButtons[${index}].websiteUrl`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`visitWebsiteButtons[${index}].websiteUrl`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.visitWebsite];
                                                                                                    newPreview[index] = { ...newPreview[index], websiteUrl: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, visitWebsite: newPreview }));

                                                                                                }}
                                                                                                className="form-control border-start-0"

                                                                                            />
                                                                                            <label>Website Url {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${visitWebsiteButton['websiteUrl'].length}/2000`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`visitWebsiteButtons[${index}].websiteUrl`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        {values.visitWebsiteButtons.length > 1 && (
                                                                            <div className="col-12 col-md-2 ">
                                                                                <button className="btn btn-sm btn-danger " type="button"
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                        // Update the Preview array
                                                                                        const newPreview = [...templatePreview.visitWebsite];
                                                                                        let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                        setTemplatePreview(prev => ({ ...prev, visitWebsite: newPreviewData }));

                                                                                    }} >X</button>
                                                                            </div>
                                                                        )}


                                                                        {values.visitWebsiteButtons.length - 1 === index && btnLimit.visitWebsiteAddBtn > 0 && (
                                                                            <button className="btn btn-sm btn-info" type="button"
                                                                                onClick={() => {
                                                                                    push({ buttonText: '', websiteUrl: '' })
                                                                                    setTemplatePreview(prev => ({ ...prev, visitWebsite: [...prev.visitWebsite, { buttonText: '', websiteUrl: '' }] }))
                                                                                }}>Add Website Url +{btnLimit.visitWebsiteAddBtn}</button>
                                                                        )}

                                                                    </div>
                                                                ))
                                                                : null}


                                                        </div>
                                                    )}
                                                </FieldArray>
                                            )}

                                            {values.buttonType.includes('callPhoneNumber') && (
                                                <FieldArray name="callPhoneNumberButtons">
                                                    {({ insert, remove, push }) => (
                                                        <div className="col-12 " style={{backgroundColor:'lightgreen'}}>
                                                            <b>Call Phone Number.....</b>
                                                            {values.callPhoneNumberButtons && values.callPhoneNumberButtons.length > 0
                                                                ? values.callPhoneNumberButtons.map((callPhoneNumberButton, index) => (
                                                                    <div className="row mb-4" key={index}>
                                                                        <div className="col-12 col-md-4">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`callPhoneNumberButtons[${index}].buttonText`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`callPhoneNumberButtons[${index}].buttonText`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.callPhoneNumber];
                                                                                                    newPreview[index] = { ...newPreview[index], buttonText: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, callPhoneNumber: newPreview }));

                                                                                                }}
                                                                                                className="form-control border-start-0"
                                                                                                maxLength="25"
                                                                                            />
                                                                                            <label>Button Text {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${callPhoneNumberButton['buttonText'].length}/25`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].buttonText`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`callPhoneNumberButtons[${index}].countryCode`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`callPhoneNumberButtons[${index}].countryCode`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.callPhoneNumber];
                                                                                                    newPreview[index] = { ...newPreview[index], countryCode: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, callPhoneNumber: newPreview }));

                                                                                                }}
                                                                                                maxLength="5"
                                                                                                className="form-control border-start-0"

                                                                                            />
                                                                                            <label>Country Code {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${callPhoneNumberButton['countryCode'].length}/5`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].countryCode`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`callPhoneNumberButtons[${index}].phoneNumber`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`callPhoneNumberButtons[${index}].phoneNumber`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.callPhoneNumber];
                                                                                                    newPreview[index] = { ...newPreview[index], phoneNumber: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, callPhoneNumber: newPreview }));

                                                                                                }}
                                                                                                maxLength="20"
                                                                                                className="form-control border-start-0"

                                                                                            />
                                                                                            <label>Phone Number {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${callPhoneNumberButton['phoneNumber'].length}/20`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].phoneNumber`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        {values.callPhoneNumberButtons.length > 1 && (
                                                                            <div className="col-12 col-md-2 ">
                                                                                <button className="btn btn-sm btn-danger " type="button"
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                        // Update the Preview array
                                                                                        const newPreview = [...templatePreview.callPhoneNumber];
                                                                                        let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                        setTemplatePreview(prev => ({ ...prev, callPhoneNumber: newPreviewData }));

                                                                                    }} >X</button>
                                                                            </div>
                                                                        )}


                                                                        {values.callPhoneNumberButtons.length - 1 === index && btnLimit.callPhoneNumberAddBtn > 0 && (
                                                                            <button className="btn btn-sm btn-info" type="button"
                                                                                onClick={() => {
                                                                                    push({ "buttonText": "", "countryCode": "", "phoneNumber": "" })
                                                                                    setTemplatePreview(prev => ({ ...prev, callPhoneNumber: [...prev.callPhoneNumber, { "buttonText": "", "countryCode": "", "phoneNumber": "" }] }))
                                                                                }}>Add Phone Number +{btnLimit.callPhoneNumberAddBtn}</button>
                                                                        )}

                                                                    </div>
                                                                ))
                                                                : null}


                                                        </div>
                                                    )}
                                                </FieldArray>
                                            )}

                                            {values.buttonType.includes('copyOfferCode') && (
                                                <FieldArray name="copyOfferCodeButtons">
                                                    {({ insert, remove, push }) => (
                                                        <div className="col-12 " style={{backgroundColor:'lightskyblue'}}>
                                                            <b>Copy Offer Code.....</b>
                                                            {values.copyOfferCodeButtons && values.copyOfferCodeButtons.length > 0
                                                                ? values.copyOfferCodeButtons.map((copyOfferCodeButton, index) => (
                                                                    <div className="row mb-4" key={index}>
                                                                        
                                                                        <div className="col-12 col-md-5">
                                                                            <div className="mb-2">
                                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                                    <div className="input-template input-template-lg">
                                                                                        <div className="form-floating">
                                                                                            <Field type="text"
                                                                                                name={`copyOfferCodeButtons[${index}].offerCode`}
                                                                                                onChange={(e) => {
                                                                                                    // Update the field value
                                                                                                    setFieldValue(`copyOfferCodeButtons[${index}].offerCode`, e.target.value);

                                                                                                    const newPreview = [...templatePreview.copyOfferCode];
                                                                                                    newPreview[index] = { ...newPreview[index], offerCode: e.target.value }
                                                                                                    setTemplatePreview(prev => ({ ...prev, copyOfferCode: newPreview }));

                                                                                                }}
                                                                                                maxLength="15"
                                                                                                className="form-control border-start-0"

                                                                                            />
                                                                                            <label>Copy Offer Code {index + 1}<span className='star'>*</span></label>

                                                                                        </div>
                                                                                        {`${copyOfferCodeButton['offerCode'].length}/15`}
                                                                                    </div>

                                                                                </div>
                                                                                <ErrorMessage name={`copyOfferCodeButtons[${index}].offerCode`} component="div" className="error" />
                                                                            </div>
                                                                        </div>
                                                                        {values.copyOfferCodeButtons.length > 1 && (
                                                                            <div className="col-12 col-md-2 ">
                                                                                <button className="btn btn-sm btn-danger " type="button"
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                        // Update the Preview array
                                                                                        const newPreview = [...templatePreview.copyOfferCode];
                                                                                        let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                        setTemplatePreview(prev => ({ ...prev, copyOfferCode: newPreviewData }));

                                                                                    }} >X</button>
                                                                            </div>
                                                                        )}


                                                                        {values.copyOfferCodeButtons.length - 1 === index && btnLimit.copyOfferCodeAddBtn > 0 && (
                                                                            <button className="btn btn-sm btn-info" type="button"
                                                                                onClick={() => {
                                                                                    push({ buttonText: 'Copy offer code', offerCode: '' })
                                                                                    setTemplatePreview(prev => ({ ...prev, copyOfferCode: [...prev.copyOfferCode, { buttonText: '', websiteUrl: '' }] }))
                                                                                }}>Add Copy Offer Code +{btnLimit.copyOfferCodeAddBtn}</button>
                                                                        )}

                                                                    </div>
                                                                ))
                                                                : null}


                                                        </div>
                                                    )}
                                                </FieldArray>
                                            )}
                                            {/* Button section end */}

                                            <div style={{ display: 'flex' }}>
                                                <button style={{ height: '36px', marginRight: '30px' }} className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />


                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                        {/* Template Form section end */}

                        {/* Template Preview section start */}
                        <div className="col-12 col-md-4">
                            <TemplatePreview templatePreview={templatePreview} />
                        </div>
                        {/* Template Preview section end */}
                    </div>
                </div>
                <div className='row'>
                    <div className=' className="col-6 col-md-6"'>

                    </div>
                    <div className='className="col-6 col-md-6"'>

                    </div>
                </div>

            </div>
        </main >
        <Footer />

    </>)
}

export default BroadcastTemplateFormNew;