// src/api/authApi.js
import axiosInstance from './axiosInstance';

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const addGroup = async (postData) => {
  try {
    const response = await axiosInstance.post('/create/group', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupList = async (postData) => {
  try {
    const response = await axiosInstance.get('/getall/group', {
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const deleteGroup = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete/group?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group contact form
let postData = {
  let postData = {
                groupId: groupId,
                userId: userId,
                name: formData.name,
                mobileNo: formData.mobileNo,
                mobileCode: '+91',
                type: type,
            }
}*/
export const groupAddContact = async (postData) => {
  try {
    const response = await axiosInstance.post('/create-group-contact', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// get group contact list
export const getGroupContactList = async (postData) => {
  try {
    const response = await axiosInstance.get(`/getall-group-contact`,{
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupDetails = async (groupId) => {
  try {
    const response = await axiosInstance.get(`/get/groupbyid?id=${groupId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/* add group form
let postData = {
  let postData = {
                name: formData.groupName,
                description: formData.description,
            }
}*/
export const deleteGroupContact = async (itemId) => {
  try {
    const response = await axiosInstance.delete(`/delete-group-contact?id=${itemId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// count group api

export const getGroupCount = async () => {
  try {
    const response = await axiosInstance.get('/group-count');
    return response.data;
  } catch (error) {
    throw error;
  }
};