import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import { loginUser } from '../../api/authApi';
import { loginInitialValues, loginSchema } from '../../utils/validationSchema';
import Loader from '../common/Loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import Header from '../Website/Header';

function Login() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { userData, storeUserDataInContext } = useAuthContext();
    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            let postData = {
                mobileNo: formData.mobileNo,
                mobileCode: '91',
                password: formData.password
            }
            let responceData = await loginUser(postData);
            let tokenInfo = responceData.token;
            let growwupInfo = { token: tokenInfo }
            storeUserDataInContext(tokenInfo);
            localStorage.setItem('growwupInfo', JSON.stringify(growwupInfo))
            toast.success('Login successful!');
            navigate('/dashboard')

        } catch (error) {
            console.log('error', error);
            const errorMessage = error.response?.data?.ErrorMessage || 'Login failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userData) {
            navigate('/dashboard')
        }
    }, [])
    return (<>
        <Header />
        {loading && <Loader />}
        <main className="main mainheight container-fluid">
            <Formik
                initialValues={loginInitialValues}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form className="row h-100 justify-content-center">
                        <div className="col-12 align-self-center">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 text-center text-black">
                                    <h3 className="mb-3 mb-lg-4" style={{ "paddingTop": "50px", "color": "#000" }}>Sign In</h3>
                                    <p className="mb-4">Enter your detail to login</p>
                                    <div className="mb-4 text-start">

                                        <div className="form-group mb-2 position-relative check-valid text-dark">
                                            <div className="input-group input-group-lg">
                                                <span className="input-group-text text-theme border-end-0"><Icon.Phone /></span>
                                                <div className="form-floating">
                                                    <Field type="text" name="mobileNo" className="form-control border-start-0" />
                                                    <label>Mobile Number </label>
                                                </div>
                                            </div>
                                        </div>
                                        <ErrorMessage name="mobileNo" component="div" className="error" />

                                        <div className="form-group mb-2 position-relative check-valid text-dark">
                                            <div className="input-group input-group-lg">
                                                <span className="input-group-text text-theme border-end-0"><Icon.Key /></span>
                                                <div className="form-floating">
                                                    <Field type="password" name="password" className="form-control border-start-0" />
                                                    <label>Password</label>
                                                </div>
                                            </div>
                                        </div>
                                        <ErrorMessage name="password" component="div" className="error" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 mt-auto mb-4 text-center d-grid">
                            <button className="btn btn-lg btn-theme z-index-5 mb-4" type="submit" disabled={isSubmitting}>
                                Login <i className="bi bi-arrow-right"></i>
                            </button>
                            <p>Don't have an account ? <b className='cursor-pointer' onClick={(e) => { navigate('/sign-up') }}>Sign Up</b></p>
                        </div>

                    </Form>
                )}
            </Formik>
        </main>
    </>)
}


export default Login;