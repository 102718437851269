import React from 'react';
const Pagination = ({ currentPage, totalItems, itemsPerPage, setCurrentPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Function to generate the page numbers array
  const getPageNumbers = () => {
    const pageNumbers = [];

    // If there's only one page, return early with just that page number
    if (totalPages === 1) {
      return [1];
    }

    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    // Add first page number
    pageNumbers.push(1);

    // Add ellipsis if there is a gap between the start pages and the middle pages
    if (startPage > 2) {
      pageNumbers.push('...');
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      if (i > 1 && i < totalPages) {
        pageNumbers.push(i);
      }
    }

    // Add ellipsis if there is a gap between the middle pages and the end pages
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }

    // Add last page number
    pageNumbers.push(totalPages);

    return pageNumbers;
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = getPageNumbers();

  return (
    <nav style={{display:'flex',justifyContent:'center'}}>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
          <button className="page-link" onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {pageNumbers.map((number, index) => (
          <li key={index} className={`page-item ${number === currentPage && 'active'}`}>
            {number === '...' ? (
              <span className="page-link">...</span>
            ) : (
              <button className="page-link" onClick={() => handleClick(number)}>
                {number}
              </button>
            )}
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
          <button className="page-link" onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
