import { useEffect, useState } from "react";
import * as Icon from 'react-bootstrap-icons';

const TemplatePreview = ({ templatePreview }) => {
    const [headerType, setHeaderType] = useState('')
    const [headerText, setHeaderText] = useState('')
    const [headerFile, setHeaderFile] = useState('')
    const [bodyPreview, setBodyPreview] = useState('');
    const [footerPreview, setFooterPreview] = useState('')
    const [buttonTypePreview, setButtonTypePreview] = useState([])



    useEffect(() => {
        // console.log('TemplatePreview...................');
        if (templatePreview) {
            // console.log('TemplatePreview:templatePreview', templatePreview);
            setHeaderType(templatePreview.headerType)
            setHeaderText(templatePreview.headerText)
            setHeaderFile(templatePreview.headerFile)
            setBodyPreview(templatePreview.body)
            setFooterPreview(templatePreview.footer)
        }
    })

    return (
        <section className="msger">
            <header className="msger-header">
                <div className="msger-header-title">
                    <i className="fas fa-comment-alt"></i> Chat Preview
                </div>
                <div className="msger-header-options">
                    <span><i className="fas fa-cog"></i></span>
                </div>
            </header>

            <main className="msger-chat">
                <div className="msg left-msg">
                    <div

                    ><Icon.PersonBoundingBox className="msg-img" /></div>

                    <div className="msg-bubble">
                        <div className="msg-info">
                            <div className="msg-info-name">BOT</div>
                            {/* <div className="msg-info-time">12:45</div> */}
                        </div>

                        <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                            {headerType === "IMAGE" && headerFile !== null && (
                                <>
                                    <img src={headerFile} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                    <br />
                                </>
                            )}

                            {headerType === "VIDEO" && headerFile !== null && (
                                <>
                                    <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                        <source src={headerFile} type="video/mp4" />

                                    </video>
                                    <br />
                                </>
                            )}

                            {headerType === "DOCUMENT" && headerFile !== null && (
                                <>
                                    <iframe
                                        src={headerFile}
                                        style={{ width: '200px', height: '200px' }}
                                        title="Document Preview"
                                    />
                                    <br />
                                </>
                            )}

                            {headerType === "TEXT" && <><b>{headerText}</b><br /><br /></>}
                            {bodyPreview && <>{bodyPreview}<br /><br /></>}
                            {footerPreview && <>{footerPreview}<br /><br /></>}

                            {templatePreview.buttonType && templatePreview.buttonType.includes("quickReply") &&
                                <>
                                    <p>Quick Reply</p>
                                    {templatePreview['quickReply'].map((button, index) => {
                                        return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.replyButton && `Button${index + 1}`}  {button.replyButton}</button>
                                    })}
                                </>
                            }

                            {templatePreview.buttonType && templatePreview.buttonType.includes("visitWebsite") &&
                                <>
                                    <p>Visit Website</p>
                                    {templatePreview['visitWebsite'].map((button, index) => {
                                        return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                    })}
                                </>
                            }

                            {templatePreview.buttonType && templatePreview.buttonType.includes("callPhoneNumber") &&
                                <>
                                    <p>Call Phone Number</p>
                                    {templatePreview['callPhoneNumber'].map((button, index) => {
                                        return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                    })}
                                </>
                            }
                            {templatePreview.buttonType && templatePreview.buttonType.includes("copyOfferCode") &&
                                <>
                                    <p>Copy Offer Code</p>
                                    {templatePreview['copyOfferCode'].map((button, index) => {
                                        return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                    })}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </main>

            <form className="msger-inputarea">
                <input type="text" className="msger-input" placeholder="Enter your message..." disabled />
                <button type="submit" className="msger-send-btn" disabled>Send</button>
            </form>
        </section>
    )
}

export default TemplatePreview;