// src/api/authApi.js
import axiosInstance from './axiosInstance';

export const getBroadcastList = async (postData) => {
  try {
    const response = await axiosInstance.get('/getall/broadcast', {
      params: postData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// create session 
export const createSession = async () => {
  try {
    const response = await axiosInstance.get('/session-create');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// send broadcast from whatsapp web

export const sendBroadcastByWeb = async (postData) => {
  try {
    const response = await axiosInstance.post('/send-broadcast-by-web', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendBroadcastByCloud = async (postData) => {
  try {
    const response = await axiosInstance.post('/send-cloud-broadcast', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// send broadcast count api

export const getBroadcastCount = async () => {
  try {
    const response = await axiosInstance.get('/broadcast-count');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBroadcastDetail = async (postData) => {
  try {
    const response = await axiosInstance.get('/broadcast-history', {
      params: postData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete session 

export const deleteSession = async (postData) => {
  try {
    const response = await axiosInstance.get('/disconnet-session');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Broadcast History Count 

export const broadcastHistoryCOunt = async (postData) => {
  try {
    const response = await axiosInstance.get('/broadcast-history-count', {
      params: postData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
